import { CONTENT_BLOCK_TYPE_ARTICLE } from '@/components/mixins/valueObject/ContentBlockTypeMixin'

const PHOTO_GALLERY_FLAG = 'Photo gallery'
const VIDEO_FLAG = 'Video'
const PODCAST_FLAG = 'Podcast'
const QUIZ_FLAG = 'Quiz'
const POLL_FLAG = 'Poll'
const DISQUS_FLAG = 'Discussion'

export const CONTENT_BLOCK_FLAGS = [
  { id: 'video', title: VIDEO_FLAG },
  { id: 'imageGallery', title: PHOTO_GALLERY_FLAG },
  { id: 'podcast', title: PODCAST_FLAG },
  { id: 'quiz', title: QUIZ_FLAG },
  { id: 'poll', title: POLL_FLAG },
  { id: 'discussion', title: DISQUS_FLAG }
]

export default {
  type: CONTENT_BLOCK_TYPE_ARTICLE,
  mainSettings: {
    hidden: false,
    position: 0,
    title: '',
    identifier: '',
    siteId: 0,
    siteName: '',
    category: {},
    isHomepage: true, // daj niekde okolo dropdownu, kde si vyberas kategoriu... Checkbox default true
    items: {
      total: 0,
      minRegional: 0,
      maxSporting: 0
    },
    contentBlockArticleType: 'topArticlesFeed', // Nevieme k comu to je
    buttonUrl: '', // Nevieme k comu to je
    buttonName: '' // Nevieme k comu to je
  },
  contentSettings: {
    sitesIds: [],
    rubricsIds: [],
    tagsIds: [],
    geneeaTagsIds: [],
    authorSourcesIds: [],
    authorsIds: [],
    categoriesIds: [],
    queryDaysLimit: 0,
    articleType: {
      article: false,
      external: false,
      video: false,
      quiz: false,
      photostory: false
    },
    settings: {
      prArticle: false
    },
    flag: {
      video: false,
      imageGallery: false,
      podcast: false,
      quiz: false,
      poll: false,
      discussion: false
    }
  },
  createdById: 0
}
