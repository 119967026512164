const messages = {
  copy: {
    first: 'HP blok',
    last: 'bol skopírovaný'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Názov',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položiek',
    external_fallback: 'Externý',
    internal_fallback: 'Článok',
    fallback_article_types: 'Typy záložných článkov',
    fallback_article_flags: 'Príznaky záložných článkov',
    hidden: 'Skryté',
    position: 'Pozícia',
    created_at: 'Vytvorené dňa',
    modified_at: 'Upravené dňa',
    created_by: 'Vytvoril'
  },
  filter: {
    site: 'Stránka',
    type: 'Typ',
    title: 'Názov',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Vyhľadávanie'
  },
  id: 'Id',
  hidden: 'Skryté',
  type: 'Typ',
  title: 'Názov',
  identifier: 'ID',
  site: 'Stránka',
  sites_and_subsites: 'Stránky/Podstránky',
  category: 'Kategória',
  number_of_items: 'Počet položiek',
  position: 'Pozícia',
  daysLimit: 'Limit dní pre dopyt',
  display_position: 'Pozícia zobrazenia',
  disabled_positions: 'Zakázané pozície',
  fallback_positions: 'Záložné pozície',
  external_fallback: 'Povoliť externú zálohu',
  internal_fallback: 'Povoliť internú zálohu',
  fallback_site: 'Stránka',
  fallback_rubrics: 'Rubriky',
  fallback_tags: 'Redakčné Tagy',
  geneea_tags: 'Geneea tagy',
  fallback_article_types: 'Typy článkov (použije sa logické ALEBO)',
  fallback_article_flags: 'Príznaky článkov (použije sa logické ALEBO)',
  fallback_article_settings: 'Ostatné',
  fallback_categories: 'Kategórie',
  source: 'Zdroj',
  content: 'Obsah',
  content_block_type_value: {
    article: 'Články',
    video_article: 'Video článok',
    video: 'Video',
    topics: 'Témy',
    specials: 'Špeciály',
    serials: 'Seriály'
  },
  main_settings: 'Hlavné nastavenia',
  fallback_settings: 'Nastavenia obsahu',
  gallery: 'Galéria',
  pr: 'Len PR články',
  items: 'Položky',
  total: 'Celkovo',
  regional_min: 'Regional min',
  sport_max: 'Sport max',
  author: 'Autor',
  internal: 'Interný',
  external: 'Externý',
  flags: 'Flagy',
  isHomepage: 'Homepage',
  article: 'Článok'
}

export default messages
